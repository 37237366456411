// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("/opt/build/repo/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-hermetiker-mdx": () => import("/opt/build/repo/src/pages/hermetiker.mdx" /* webpackChunkName: "component---src-pages-hermetiker-mdx" */),
  "component---src-pages-index-mdx": () => import("/opt/build/repo/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

